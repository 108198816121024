<template>
  <div>
    <Header :breadcrumb="$options.BREADCRUMBS.MANAGERS" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Header from '@/components/atoms/Header.vue'
import { BREADCRUMBS } from '@/constants/breadcrumb'
export default {
  BREADCRUMBS,
  components: {
    Header
  }
}
</script>
